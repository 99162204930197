import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

class Footer extends React.Component {
  render() {
    return (
      <Navbar fixed={this.props.fixed} bg="custom" variant="custom" expand="lg">
        <Nav>
          <Navbar.Text>Copyright &copy; Chefmance 2020</Navbar.Text>
          <Nav.Item>
            <Nav.Link href="#/home/terms">Terms and conditions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#/home/privacy">Privacy policy</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    )
  }
}

export default Footer;