import {
  RECEIEV_RECIPES,
  REQUESTING_RECIPES,
  REQUESTING_RECIPE_BY_ID,
  RECEIEV_RECIPE_BY_ID,
  SAVING_RECIPE,
  ADD_OR_UPDATE_RECIPE,
} from '../actions/action-types';

const defaultState = {
  recipes: [],
  fetchingRecipes: false,
  fetchingRecipeById: false,
  currentRecipe: undefined,
  savingRecipe: false,
};

const replaceRecipe = (recipes, recipeToSave) => {
  return recipes.map((recipe) => {
    if (recipe.id !== recipeToSave.id) {
      return recipe;
    }
    return {
      ...recipe,
      ...recipeToSave,
    };
  })
};

const addOrUpdateRecipe = (recipes, recipeToSave) => {
  const existingRecipe = recipes.find(recipe => recipe.id === recipeToSave.id);
  if (!existingRecipe) {
    return [
      ...recipes,
      recipeToSave,
    ];
  }
  return replaceRecipe(recipes, recipeToSave);
};

const recipeList = (state = defaultState, action) => {
  switch (action.type) {
    case REQUESTING_RECIPES:
      return {
        ...state,
        fetchingRecipes: true,
      };
    case RECEIEV_RECIPES:
      return {
        ...state,
        recipes: action.recipes,
        fetchingRecipes: false,
      }
    case REQUESTING_RECIPE_BY_ID:
      return {
        ...state,
        fetchingRecipeById: true,
      };
    case RECEIEV_RECIPE_BY_ID:
      return {
        ...state,
        currentRecipe: action.recipe,
        fetchingRecipeById: false,
      };
    case SAVING_RECIPE:
      return {
        ...state,
        savingRecipe: action.flag,
      }
    case ADD_OR_UPDATE_RECIPE:
      return {
        ...state,
        recipes: addOrUpdateRecipe(state.recipes, action.recipe),
      };
    default:
      return state;
  }
};

export default recipeList;