import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Link } from "react-router-dom";
import { loadCategories } from '../state/store/actions/categories';

import { getImagesEndpoint } from '../constants/image-endpoings';

const getImagePath = (category) => {
  const imageId = category.images[0];
  return `${getImagesEndpoint()}/${imageId}`;
};

class RecipesCategories extends React.Component {
  componentDidMount() {
    const { dispatch, categories } = this.props;
    if (!categories || categories.length === 0) {
      dispatch(loadCategories());
    }
  }
  render() {
    const { categories } = this.props;
    return (
      <React.Fragment>
        <h2>
          Recipe categories
          {/* {(!categories || categories.length === 0) && 
            (<Spinner animation="grow" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>)
          } */}
        </h2>
        <Row>
          <Col md={12} className="category-card-when-empty mb-2">
            { categories && categories.map((category) => (
              <Card className="mt-1">
                <Card.Body>
                  <Row lg={12}>
                    <Col lg={2}>
                      <Card.Img className="img-list" src={getImagePath(category)} />
                    </Col>
                    <Col lg={10}>
                      <Card.Title>{category.name}</Card.Title>
                      <Card.Text>
                        {category.description}
                      </Card.Text>
                      <Button variant="primary">
                        <Link className="link-primary" to={`/home/recipes/${category.id}`}>View recipes</Link>
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>

              </Card>
            ))}

          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

RecipesCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  fetchingCategories: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { categories, fetchingCategories } = state.itemCategories;
  return {
    categories,
    fetchingCategories
  }
}

export default connect(mapStateToProps)(RecipesCategories);

