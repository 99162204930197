import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Route } from "react-router-dom";

import AboutUs from './AboutUs';
import ContactUsForm from './ContactUs';
import TopNav from './TopNav';
import RecipesLanding from './RecipesLanding';
import RecipesCategories from './RecipesCategories';
import Videos from './Videos';
import HomeContents from './HomeContents';
import Footer from './Footer';
import Terms from './Terms';
import Privacy from './Privacy';

class Home extends React.Component {

render() {
  return (    
    <React.Fragment>
      <TopNav />
      <Container fluid="lg">
        <Row lg={12}>
          <Col lg={12}>
            <Route exact path={`${this.props.match.path}`} component={HomeContents} />
            <Route path={`${this.props.match.path}/recipe-categories`} component={RecipesCategories}  />
            <Route path={`${this.props.match.path}/videos`} component={Videos}  />
            <Route path={`${this.props.match.path}/recipes`} component={RecipesLanding}  />
            <Route path={`${this.props.match.path}/terms`} component={Terms}  />
            <Route path={`${this.props.match.path}/privacy`} component={Privacy}  />
            <Route path={`${this.props.match.path}/about`} component={AboutUs} />
            <Route path={`${this.props.match.path}/contact`} component={ContactUsForm} />
          </Col>
        </Row>
        <Row lg={12}>
          <Col lg={12} className="m-1">
            <Footer />
          </Col>
        </Row>
      </Container>
    </React.Fragment>  
    );
  }
}

export default Home;
