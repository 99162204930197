import React from 'react';
import { Redirect, Route } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../Footer';
import AdminNav from './AdminNav';

// dynamic stuff
import ManageCategories from './ManageCategories'
import ManageRecipes from './ManageRecipes';

class AdminLanding extends React.Component {
  render() {
    return (
      true ?
      (<React.Fragment>
      <AdminNav />
      <Container fluid="lg">
        <Row lg={12}>
          <Col lg={12}>
            <Route exact path={this.props.match.path} render={() => (
              <Redirect to={`${this.props.match.path}/recipe-categories`} />
            )} />
            <Route path={`${this.props.match.path}/recipe-categories`} component={ManageCategories} />
            <Route path={`${this.props.match.path}/recipes`} component={ManageRecipes} />
          </Col>
        </Row>
        <Row lg={12}>
          <Col lg={12} className="m-1">
            <Footer />
          </Col>
        </Row>
      </Container>
      </React.Fragment>) :
      (<Redirect to="/admin-login" />)
    );
  }
}

export default AdminLanding;
