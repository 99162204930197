import React from 'react';

class CaretRight extends React.Component {
  render() {
    return (
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-right caret-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
      </svg>    
    )
  }
}
export default CaretRight;