import React from 'react';

class LogoIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="chefmance-logo-top" width="28" height="28" viewBox="0 0 32 32">
        <title>Logo icon</title>
        <path d="M29.5 21h-2.7c0-0.2-0.1-0.5-0.2-0.7l4.8-2.4c0.5-0.2 0.7-0.8 0.4-1.3-0.2-0.5-0.8-0.7-1.3-0.4l-4.6 2.3c-1.7-3.8-5.6-6.4-10-6.4-5.4 0-9.9 3.9-10.8 9h-2.7c-1.4 0-2.5 1.1-2.5 2.5 0 0.5 0.2 1 0.5 1.3l3.5 3.9v0.7c0 1.4 1.1 2.5 2.5 2.5h19c1.4 0 2.5-1.1 2.5-2.5v-0.7l3.5-3.9c0.3-0.4 0.5-0.8 0.5-1.3 0-1.4-1.1-2.5-2.5-2.5zM25.8 21h-0.6l0.5-0.2c0 0.1 0 0.2 0.1 0.3zM16 13c4 0 7.5 2.4 9.1 5.8l-0.9 0.4c-1.4-3.1-4.5-5.3-8.2-5.3-4.3 0-7.9 3-8.8 7h-1c0.9-4.6 5-8 9.8-8zM20.6 21c-0.8-1.8-2.5-3-4.6-3s-3.8 1.2-4.6 3h-1.1c0.8-2.3 3-4 5.7-4 2.5 0 4.6 1.5 5.5 3.6l-0.9 0.4zM16 20c-0.9 0-1.7 0.4-2.2 1h-1.2c0.7-1.2 2-2 3.4-2 1.5 0 2.8 0.8 3.4 2h-1.2c-0.5-0.6-1.3-1-2.2-1zM16 16c-3.2 0-5.8 2.1-6.7 5h-1c0.9-3.4 4-6 7.7-6 3.2 0 6 1.9 7.3 4.7l-0.9 0.4c-1.1-2.5-3.5-4.2-6.4-4.2zM26 28v1.5c0 0.3-0.2 0.5-0.5 0.5h-19c-0.3 0-0.5-0.2-0.5-0.5v-1.5l-4-4.5c0-0.3 0.2-0.5 0.5-0.5h27c0.3 0 0.5 0.2 0.5 0.5l-4 4.5zM8 11.7c0 0 0 0 0 0-0.5-1.3 0-2.5 0.5-3.6 0.4-1.1 0.8-2 0.4-2.9 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-0.1 0-0.1 0-0.2 0-0.3 0.2-0.5 0.5-0.5 0.2 0 0.4 0.1 0.5 0.3 0.6 1.3 0.1 2.5-0.4 3.6-0.4 1-0.8 2-0.4 2.9 0 0 0 0 0 0 0 0 0 0.1 0 0.1 0 0.3-0.2 0.5-0.5 0.5-0.2 0-0.4-0.1-0.4-0.3 0 0 0 0 0 0 0 0 0 0 0 0zM20.2 11.6c0 0 0 0 0 0-0.5-1.3 0-2.5 0.5-3.6 0.4-1.1 0.8-2 0.4-2.9 0 0 0 0 0 0s0 0 0 0c0 0 0 0 0 0 0-0.1 0-0.1 0-0.2 0-0.3 0.2-0.5 0.5-0.5 0.2 0 0.4 0.1 0.5 0.3 0.6 1.3 0.1 2.5-0.4 3.6-0.4 1-0.8 2-0.4 2.9 0 0 0 0 0 0 0 0 0 0.1 0 0.1 0 0.3-0.2 0.5-0.5 0.5-0.2 0-0.4-0.1-0.4-0.3h0c0 0 0 0 0 0zM15 7.2c0 0 0 0 0 0-0.5-1.3 0-2.5 0.5-3.6 0.4-1.1 0.8-2 0.4-2.9 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-0.1 0-0.1 0-0.2 0-0.3 0.2-0.5 0.5-0.5 0.2 0 0.4 0.1 0.5 0.3 0.6 1.3 0.1 2.5-0.4 3.6-0.4 1-0.8 2-0.4 2.9 0 0 0 0 0 0 0 0 0 0.1 0 0.1 0 0.3-0.2 0.5-0.5 0.5-0.2 0-0.4-0.1-0.4-0.3 0 0 0 0 0 0 0 0 0 0 0 0z"/>
      </svg>
    )
  }
}
export default LogoIcon;