import fetch from 'cross-fetch';
import {
  RECEIVE_CATEGORIES,
  REQUESTING_CATEGORIES,
  REPLACE_CATEGORY,
  ADD_CATEGORY,
  SAVING_CATEGORY,
} from './action-types';

import { getCategoriesEndpoint } from '../service-endpoints';


const requestingCategories = () => ({
  type: REQUESTING_CATEGORIES,
});

const receiveCategoriesAction = (categories) => ({
  type: RECEIVE_CATEGORIES,
  categories,
});

const savingCategoryAction = (flag) => ({
  type: SAVING_CATEGORY,
  flag,
});

const replaceCategoryAction = (category) => ({
  type: REPLACE_CATEGORY,
  category,
});

const addCategoryAction = (category) => ({
  type: ADD_CATEGORY,
  category,
});

const fetchCategories = () => {
  return dispatch => {
    dispatch(requestingCategories())
    return fetch(`${getCategoriesEndpoint()}/recipe-categories`)
    .then(response => response.json())
    .then(json => dispatch(receiveCategoriesAction(json)))

  };
};

const udateCategory = (category) => {
  return dispatch => {
    dispatch(savingCategoryAction(true));
    return fetch('http://localhost:8080/recipe-categories', {
      method: 'POST',
      body: JSON.stringify(category),
    })
    .then(response => response.json())
    .then((json) => {
      dispatch(replaceCategoryAction(json))
      dispatch(savingCategoryAction(false));
    })
  };
};

const addCategory = (category) => {
  return dispatch => {
    dispatch(savingCategoryAction(true));
    return fetch('http://localhost:8080/recipe-categories', {
      method: 'POST',
      body: JSON.stringify(category),
    })
    .then(response => response.json())
    .then((json) => {
      dispatch(addCategoryAction(json))
      dispatch(savingCategoryAction(false));
    })
  };
};

export const loadCategories = () => {
  return (dispatch) => {
    return dispatch(fetchCategories());
  }
};

export const replaceCategory = (category) => {
  return (dispatch) => {
    return dispatch(udateCategory(category));
  };
};

export const addNewCategory = (category) => {
  return (dispatch) => {
    return dispatch(addCategory(category));
  };
};