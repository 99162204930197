import fetch from 'cross-fetch';
import {
  SUBMITTING_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
} from './action-types';

import { getPublicFeedbackEndpoint } from '../service-endpoints';

const submittingFeedbackAction = (flag, feedback) => ({
  type: SUBMITTING_FEEDBACK,
  flag,
  feedback,
});

const submittingFeedbackSuccessAction = () => ({
  type: SUBMIT_FEEDBACK_SUCCESS,
});

const addFeedback = (feedback) => {
  return dispatch => {
    dispatch(submittingFeedbackAction(true));
    return fetch(`${getPublicFeedbackEndpoint()}/public-feedback`, {
      method: 'POST',
      body: JSON.stringify(feedback),
    })
    .then(response => response.json())
    .then(() => {
      dispatch(submittingFeedbackAction(false));
      dispatch(submittingFeedbackSuccessAction());
    })
  };
};

export const submitFeedback = (feedback) => {
  return (dispatch) => {
    return dispatch(addFeedback(feedback));
  };
};