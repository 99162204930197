import React from 'react';

import { Route, Redirect } from "react-router-dom";

import RecipeList from './RecipeList';
import RecipeDetails from './RecipeDetails'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class RecipesLanding extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={this.props.match.url}
          render={() => (
            <Redirect to={`${this.props.match.url}/all`} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/:category`}
          component={RecipeList}
        />
        <Route
          path={`${this.props.match.url}/view/:id`}
          component={RecipeDetails}
        />
      </React.Fragment>
    );
  }
}

export default RecipesLanding;
