import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card'

class Videos extends React.Component {
  render() {
    return (
      <Row>
        <Col lg={12} className="m-1">
          <Card>
            <Card.Body>
              <Card.Title>Videos</Card.Title>
              <Card.Text>
             Videoss ...
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Videos;