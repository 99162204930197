import fetch from 'cross-fetch';
import {
  RECEIEV_RECIPES,
  REQUESTING_RECIPES,
  REQUESTING_RECIPE_BY_ID,
  RECEIEV_RECIPE_BY_ID,
  ADD_OR_UPDATE_RECIPE,
  SAVING_RECIPE,
} from './action-types';

import { getRecipesEndpoint } from '../service-endpoints';

// getting recipe list
const requestingRecipes = () => ({
  type: REQUESTING_RECIPES,
});

const receiveRecipesAction = (recipes) => ({
  type: RECEIEV_RECIPES,
  recipes,
});

// getting recipe by id
const requestingRecipeById = () => ({
  type: REQUESTING_RECIPE_BY_ID,
});

const receiveRecipeByIdAction = (recipe) => ({
  type: RECEIEV_RECIPE_BY_ID,
  recipe,
});

// add / update recipe
const savingRecipe = (flag) => ({
  type: SAVING_RECIPE,
  flag,
});

const addOrUpdateRecipeAction = (recipe) => ({
  type: ADD_OR_UPDATE_RECIPE,
  recipe,
});

const fetchRecipes = (filter) => {
  return dispatch => {
    dispatch(requestingRecipes())
    return fetch(`${getRecipesEndpoint()}/recipe-list?filter=${JSON.stringify(filter)}`)
    .then(response => response.json())
    .then(json => dispatch(receiveRecipesAction(json)))
  };
};

const fetchRecipeById = (id) => {
  return dispatch => {
    dispatch(requestingRecipeById())
    return fetch(`${getRecipesEndpoint()}/recipe-list/${id}`)
    .then(response => response.json())
    .then(json => dispatch(receiveRecipeByIdAction(json)))

  };
};

const addOrUpdateRecipe = (recipe) => {
  return dispatch => {
    dispatch(savingRecipe(true));
    return fetch('http://localhost:8081/recipe-list', {
      method: 'POST',
      body: JSON.stringify(recipe),
    })
    .then(response => response.json())
    .then((json) => {
      dispatch(addOrUpdateRecipeAction(json));
      return dispatch(savingRecipe(false));
    })
  };
};


export const loadRecipes = (year) => {
  return (dispatch) => {
    return dispatch(fetchRecipes(year));
  }
};

export const loadRecipeById = (id) => {
  return (dispatch) => {
    return dispatch(fetchRecipeById(id));
  }
};

export const saveRecipe = (recipe) => {
  return (dispatch) => {
    return dispatch(addOrUpdateRecipe(recipe));
  }
}