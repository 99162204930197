import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addNewCategory } from '../../../state/store/actions/categories';

class SaveCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        ...props.category,
      },
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    dispatch(addNewCategory(this.state.category));
  }
  handleChange = (evt) => {
    this.setState({
      category: {
        ...this.state.category,
        name: evt.target.value,
      },
    });
  }
  render() {
    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Category name</Form.Label>
            <Form.Control onChange={this.handleChange} value={this.state.category.name} type="text" placeholder="Category name" />
            <Form.Text className="text-muted">
              Enter the name of the category
            </Form.Text>
          </Form.Group>
          <Button className="save-button" variant="primary" type="submit" disabled={this.props.savingCategory}>
            {(
              this.props.savingCategory ?
              <React.Fragment>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                >
                  <span className="sr-only">Saving...</span>
                </Spinner>
                <span>&nbsp;Saving...</span>
              </React.Fragment>
              : <span>Save</span>
            )}
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

SaveCategory.propTypes = {
  categories: PropTypes.array.isRequired,
  fetchingCategories: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { savingCategory } = state.itemCategories;
  return {
    savingCategory
  }
}

export default connect(mapStateToProps)(SaveCategory)
