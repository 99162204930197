const endpoints = {
  LOCAL: 'http://localhost:4572/chefmance-recipe-images',
  REMOTE: '/recipe-images',
};

const isLocal = () => (window.location.host.indexOf('localhost') !== -1);

export const getImagesEndpoint = () => (
  isLocal() ? endpoints.LOCAL : endpoints.REMOTE
);
