import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import CookingInstructions from './CookingInstructions';
import IngredientsList from './IngredientsList';
import RecipeFacts from './RecipeFacts';
import CookwareList from './CookwareList';
import CaretRight from './icons/CaretRight';
import CaretLeft from './icons/CaretLeft';

import { loadRecipeById } from '../state/store/actions/recipes';

import { getImagesEndpoint } from '../constants/image-endpoings';

const getImagePath = imageId => (`${getImagesEndpoint()}/${imageId}`);

const ImageSlider = (props) => (
  <Carousel nextIcon={(<CaretRight />)} prevIcon={(<CaretLeft />)}>
    {
      props.recipe.images.map((imageId) => (
        <Carousel.Item interval={1500}>
          <Image className="recipe-full-img" src={getImagePath(imageId)} />
        </Carousel.Item>
      ))
    }
  </Carousel>
);

class RecipeDetails extends React.Component {
  findRecipe(id) {
    const { recipes } = this.props;
    return recipes.find((recipe) => (recipe.id === id))
  }
  getServingSize(recipe) {
    const fact = recipe.facts.find((fact) => (fact.id === 'serving_size'));
    return fact ? fact.value : '';
  }
  getPrepTime(recipe) {
    const fact = recipe.facts.find((fact) => (fact.id === 'prep_time'));
    return fact ? fact.value : '';
  }
  cooking_time(recipe) {
    const fact = recipe.facts.find((fact) => (fact.id === 'cooking_time'));
    return fact ? fact.value : '';
  }
  render() {
    const { match, currentRecipe } = this.props;
    const { id } = match.params;
    const searchedRecipe = this.findRecipe(id);
    if (!searchedRecipe && !currentRecipe) {
      this.props.dispatch(loadRecipeById(id))
    }
    const recipe = searchedRecipe || currentRecipe;
    return (
      <React.Fragment>
        {!recipe && (
          (
            <Row className="justify-content-md-center">
              <Col md={9} className="m-2">
                <Spinner animation="grow" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                <span>&nbsp;Loading your recipe...</span>
              </Col>
          </Row>
          )
        )}
        {recipe && (
          <React.Fragment>
            <Row className="justify-content-md-center">
              <Col md={9} className="m-3">
                <Card className="card-no-border">
                  <Card.Body className="p-0">
                    <Card.Title className="recipe-details-title text-center pb-3 pt-3">{recipe.title}</Card.Title>
                    <ImageSlider recipe={recipe} />
                    <Row className="m-0 p-2 recipe-section-with-bg">
                      <Col md={4}><b>Serves:</b> {this.getServingSize(recipe)}</Col>
                      <Col md={4}><b>Prep time:</b> {this.getPrepTime(recipe)}</Col>
                      <Col md={4}><b>Cooking time:</b> {this.cooking_time(recipe)}</Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={9}>
                <p>
                    {recipe.description}
                </p>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={9} className="pt-3">
                <CookwareList cookwareList={recipe.cookwareList} />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={9}>
                <Row>
                  <Col md={6} className="pt-3">
                    <IngredientsList ingredients={recipe.ingredients} />
                  </Col>
                  <Col md={6} className="pt-3">
                    <CookingInstructions instructionSets={recipe.instructionSets} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={9}>
                <RecipeFacts facts={recipe.facts} />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

RecipeDetails.propTypes = {
  recipes: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  const { recipes, currentRecipe } = state.recipeList;
  return {
    recipes,
    currentRecipe,
  }
}

export default connect(mapStateToProps)(RecipeDetails);

