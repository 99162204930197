import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

class AdminNav extends React.Component {
  render() {
    return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="#/admin">Chefmance Admin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item>
              <Nav.Link href="#/admin/recipe-categories">Manage categories</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#/admin/recipes">Manage items</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#/admin/orders">Manage orders</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default AdminNav;