import React from 'react';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadCategories } from '../../state/store/actions/categories';
import SaveCategory from './forms/SaveCategory'


class ManageCategories extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadCategories());
  }
  render() {
    const { categories } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col lg={12} className="m-1">
            <Card>
              <Card.Body>
                <Card.Title>Admin - Manage categores</Card.Title>
                <Card.Text>
                  View, add, update or delete categories.
                </Card.Text>
                <SaveCategory />
                <h4>Available categores</h4>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Category name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {categories.length > 0 && (
                    categories.map((category, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{category.name}</td>
                          <td><Button variant="secondary" size="sm">Edit</Button></td>
                        </tr>
                      )
                    )
                  )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

ManageCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  fetchingCategories: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { categories, fetchingCategories } = state.itemCategories;
  return {
    categories,
    fetchingCategories
  }
}

export default connect(mapStateToProps)(ManageCategories)
