import {
  SUBMITTING_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
} from '../actions/action-types';

const defaultState = {
  submittingFeedback: false,
  submitFeedbackSuccess: false,
};


const publicFeedback = (state = defaultState, action) => {
  switch (action.type) {
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        submitFeedbackSuccess: true,
      }
    case SUBMITTING_FEEDBACK:
      return {
        ...state,
        submittingFeedback: action.flag,
      };
    default:
      return state;
  }
};

export default publicFeedback;