const API_GATEWAY_BASE_URL="https://61uj38mwka.execute-api.us-east-1.amazonaws.com/prod";
const API_ENDPOINTS = {
  CATEGORIES: {
    LOCAL: 'http://localhost:8080',
    REMOTE: API_GATEWAY_BASE_URL,
  },
  RECIPE_LIST: {
    LOCAL: 'http://localhost:8081',
    REMOTE: API_GATEWAY_BASE_URL,
  },
  PUBLIC_FEEDBACK: {
    LOCAL: 'http://localhost:8082',
    REMOTE: API_GATEWAY_BASE_URL,
  }
};

const isLocalEnv = () => window.location.host.indexOf('localhost') !== -1

export const getCategoriesEndpoint = () => {
  return isLocalEnv() ? API_ENDPOINTS.CATEGORIES.LOCAL : API_ENDPOINTS.CATEGORIES.REMOTE;
}

export const getRecipesEndpoint = () => {
  return isLocalEnv() ? API_ENDPOINTS.RECIPE_LIST.LOCAL : API_ENDPOINTS.RECIPE_LIST.REMOTE;
}

export const getPublicFeedbackEndpoint = () => {
  return isLocalEnv() ? API_ENDPOINTS.PUBLIC_FEEDBACK.LOCAL : API_ENDPOINTS.PUBLIC_FEEDBACK.REMOTE;
}