import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import LogoIcon from './icons/LogoIcon'

import { connect } from 'react-redux'

import PropTypes from 'prop-types'

class TopNav extends React.Component {
  state = {
    activeKey: '#/home',
  }
  isActive(route) {
    var currentLocation = window.location.hash;
    return currentLocation === route;
  }
  setActiveKey(key) {
    this.setState({
      activeKey: key,
    });
  }
  render() {
    return (
      <Navbar bg="custom" variant="custom" expand="lg">
        <Container>
          <Navbar.Brand href="#/home">
            <LogoIcon />
            <span class="h4">&nbsp;Chefmance</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse collapseOnSelect={true} id="basic-navbar-nav" className="justify-content-end">
            <Nav defaultActiveKey="#/home" activeKey={this.state.activeKey}>
              <Nav.Item>
                <Nav.Link onClick={() => this.setActiveKey('#/home')} href="#/home">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => this.setActiveKey('#/home/recipes')} href="#/home/recipes">View recipes</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => this.setActiveKey('#/home/recipe-categories')} href="#/home/recipe-categories">Categories</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => this.setActiveKey('#/home/contact')} href="#/home/contact">Contact us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => this.setActiveKey('#/home/about')} href="#/home/about">About Us</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

  
}

TopNav.propTypes = {
  categories: PropTypes.array.isRequired,
  fetchingCategories: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(TopNav);

