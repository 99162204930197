import React from 'react';
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'


class RecipeFacts extends React.Component {
  render() {
    const { facts } = this.props;
    return (
      <div className="mt-2">
        <ListGroup>
          <ListGroup.Item variant="secondary">
            <h5>Recipe facts</h5>
          </ListGroup.Item>
          {facts && facts.map((fact) => (
            <ListGroup.Item>
              <b>{fact.title}:&nbsp;</b>
              {fact.value}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    )
  }
}

export default RecipeFacts;