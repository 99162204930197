import {
  REQUESTING_CATEGORIES,
  RECEIVE_CATEGORIES,
  REPLACE_CATEGORY,
  ADD_CATEGORY,
  SAVING_CATEGORY,
} from '../actions/action-types';

const defaultState = {
  categories: [],
  fetchingCategories: false,
  savingCategory: false,
};

const replaceCategory = (categories, category) => {
  const categoryIndex = categories.findIndex((c) => c.id === category.id);
  return categories.map((c, index) => {
    if (index !== categoryIndex) {
      return c;
    }
    return {
      ...c,
      ...category,
    };
  });
};

const itemCategories = (state = defaultState, action) => {
  switch (action.type) {
    case REQUESTING_CATEGORIES:
      return {
        ...state,
        fetchingCategories: true,
      };
    case RECEIVE_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      }
    case REPLACE_CATEGORY:
      return {
        ...state,
        categories: replaceCategory(state.categories, action.category),
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.category],
      };
    case SAVING_CATEGORY:
      return {
        ...state,
        savingCategory: action.flag,
      };
    default:
      return state;
  }
};

export default itemCategories;