import React from 'react';
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { Link } from "react-router-dom";
import { getImagesEndpoint } from '../constants/image-endpoings';

const getImagePath = (recipe) => {
  const imageId = recipe.images[0];
  return `${getImagesEndpoint()}/${imageId}`;
};

class RecipeCard extends React.Component {
  render() {
    const { recipe, imageClassName } = this.props;
    const imgClass = imageClassName || 'recipe-card-img';
    return (
      <Card className="card-when-empty">
        {recipe && (
          <Card.Body className="p-0 pb-1">
            <Link to={`/home/recipes/view/${recipe.id}`}>
              <Image rounded className={imgClass} src={getImagePath(recipe)} />
            </Link>
            <Card.Title className="p-2 mb-0 text-center">
              <Link className="text-dark" to={`/home/recipes/view/${recipe.id}`}>
                {recipe.title}
              </Link>
            </Card.Title>
            {/* <Card.Text className="p-2">{`${recipe.description.substring(0, 100)}...`}</Card.Text> */}
            {/* <Link className="m-2 float-right" to={`/home/recipes/view/${recipe.id}`}>View this recipe</Link> */}
          </Card.Body>
        )}
      </Card>
    )
  }
}

export default RecipeCard;