import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { submitFeedback } from '../state/store/actions/public-feedback';


class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.submitFeedbackSuccess === this.props.submitFeedbackSuccess) {
      return;
    }
    if (this.props.submitFeedbackSuccess
        && !this.props.submittingFeedback) {
      this.setState({
        name: '',
        email: '',
        message: '',  
      });
    }
  }

  handleNameChange = (evt) => {
    this.setState({
      name: evt.target.value,
    });
  }
  handleEmailChange = (evt) => {
    this.setState({
      email: evt.target.value,
    });
  }
  handleMessageChange = (evt) => {
    this.setState({
      message: evt.target.value,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    dispatch(submitFeedback(this.state));
  }
  render() {
    return (
      <Row>
        <Col lg={12} className="m-1">
          <Card>
            <Card.Body>
              <Card.Title>Contact us</Card.Title>
              <Card.Text>
                <p>Got questions, thoughts? Please feel free to write to us.</p>
              </Card.Text>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="senderName">
                  <Form.Label>Your name</Form.Label>
                  <Form.Control onChange={this.handleNameChange} value={this.state.name} type="text" placeholder="Enter your name" />
                  <Form.Text className="text-muted">
                    Please enter your full name.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="senderEmail">
                  <Form.Label>Your email address</Form.Label>
                  <Form.Control onChange={this.handleEmailChange} value={this.state.email} type="email" placeholder="Enter your email address" />
                  <Form.Text className="text-muted">
                    Your email address so we can reach out to you (we do not share it with anyone).
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="senderMessage">
                  <Form.Label>Your message</Form.Label>
                  <Form.Control onChange={this.handleMessageChange} value={this.state.message} as="textarea" rows="5" placeholder="Type your message here" />
                </Form.Group>
                <Alert show={!this.props.submittingFeedback && this.props.submitFeedbackSuccess} variant="success">
                  <Alert.Heading>Thank you</Alert.Heading>
                  <p>
                    Your feedback has been submitted. We will reach out to you soon if needed.
                  </p>
                  <div className="d-flex">
                    <Button variant="outline-success">
                      <Link to={`/home/recipes`}>
                        Go to recipes
                      </Link>
                    </Button>
                  </div>
                </Alert>
                <Button className="save-button" variant="custom" type="submit" disabled={this.props.submittingFeedback}>
                  {(
                    this.props.submittingFeedback ?
                    <React.Fragment>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      >
                        <span className="sr-only">Saving...</span>
                      </Spinner>
                      <span>&nbsp;Submitting...</span>
                    </React.Fragment>
                    : <span>Submit</span>
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}

ContactUs.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { submittingFeedback, submitFeedbackSuccess } = state.publicFeedback;
  return {
    submittingFeedback,
    submitFeedbackSuccess,
  };
}

export default connect(mapStateToProps)(ContactUs)
