import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";
import RecipeCard from './RecipeCard';
import RecipeList from './RecipeList';
import { loadRecipes } from '../state/store/actions/recipes';
import { getImagesEndpoint } from '../constants/image-endpoings';

const getImagePath = (recipe) => {
  const imageId = recipe.images[0];
  return `${getImagesEndpoint()}/${imageId}`;
};


const getSpinner = () => (
  <Spinner animation="grow" role="status">
  <span className="sr-only">Loading...</span>
  </Spinner>
)

const getRandomIndex = (max) => Math.floor(Math.random() * Math.floor(max));

const TopRecipeCard = (props) => (
  <Card className="top-recipe-when-empty">
    {props.recipe && (
    <Card.Body>
        <Row>
        <Col md={4}>
        <Link to={`/home/recipes/view/${props.recipe.id}`}>
          <Card.Img className="top-recipe-small-img" src={getImagePath(props.recipe)} />
        </Link>            
        </Col>
        <Col md={8}>
          <Card.Title>
            <Link to={`/home/recipes/view/${props.recipe.id}`}>
              {props.recipe.title}
            </Link>                            
            </Card.Title>
          <Card.Text>{
            props.recipe.description.length > 20
              ? `${props.recipe.description.substring(0, 17)}...`
              : props.recipe.description
          }</Card.Text>
        </Col>
      </Row>
    </Card.Body>
    )}
  </Card>
)
class HomeContents extends React.Component {
  componentDidMount() {
    const { dispatch, recipes } = this.props;
    if (!recipes || recipes.length === 0) {
      dispatch(loadRecipes());
    }
  }
  getRecipeOfTheDay = () => {
    const { recipes } = this.props;
    return recipes[getRandomIndex(recipes.length - 1)];
  }
  getTopQuickRecipe = () => {
    const { recipes } = this.props;
    return recipes.find((r) => (
      r.categories.includes('quick-meals')
    ));
  }
  getTopBreakfastRecipe = () => {
    const { recipes } = this.props;
    const recipe = recipes.find((r) => (
      r.servingTimes.includes('breakfast')
    ));
    return recipe;
  }
  getTopLunchRecipe = () => {
    const { recipes } = this.props;
    const recipe = recipes.find((r) => (
      r.servingTimes.includes('lunch')
    ));
    return recipe;
  }
  getTopDinnerRecipe = (selectedLunchRecipe) => {
    const { recipes } = this.props;
    const recipe = recipes.find((r) => (
      r.servingTimes.includes('dinner')
      && r.id !== selectedLunchRecipe.id
    ));
    return recipe;
  }
  render() {
    const { recipes } = this.props;
    const recipeOfTheDay = this.getRecipeOfTheDay();
    const quickRecipe = this.getTopQuickRecipe();
    const breakfastRecipe = this.getTopBreakfastRecipe();
    const lunchRecipe = this.getTopLunchRecipe();
    const dinnerRecipe = this.getTopDinnerRecipe(lunchRecipe);
    return (
      <React.Fragment>
          <Row className="justify-content-md-center">
            <Col md={4} className="pt-4 mt-1">
              <h4 className="text-center mb-4 mt-2">Recipe of the day</h4>
              <RecipeCard imageClassName="recipe-of-day-img" recipe={recipeOfTheDay} />
            </Col>
            <Col md={4} className="pt-4 mt-1">
              <h4 className="text-center mb-4 mt-2">Hungry? Try this quick recipe</h4>
              <RecipeCard imageClassName="recipe-of-day-img" recipe={quickRecipe} />
            </Col>
          </Row>
          <Row lg={12} className="bg-light mt-3 mb-3 p-3">
            <Col lg={4}>
              <h4 className="text-muted">Top breakfast recipes</h4>
              <TopRecipeCard recipe={breakfastRecipe} />
            </Col>
            <Col lg={4}>
              <h4 className="text-muted">Top lunch recipes</h4>
              <TopRecipeCard recipe={lunchRecipe} />
            </Col>
            <Col lg={4}>
              <h4 className="text-muted">Top dinner recipes</h4>
              <TopRecipeCard recipe={dinnerRecipe} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <RecipeList recipesPreLoaded={recipes} />
            </Col>
          </Row>
      </React.Fragment>
    );
  }
}

HomeContents.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { recipes } = state.recipeList;
  return {
    recipes,
  }
};

export default connect(mapStateToProps)(HomeContents);

