import React from 'react';
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'


class CookingInstructions extends React.Component {
  createHtml (step) {
    return (
      <span dangerouslySetInnerHTML={{__html: step}}></span>
    );
  }
  render() {
    const { instructionSets } = this.props;
    return (
      <React.Fragment>
        <div className="h5">Instructions</div>
        <div>
          {instructionSets.map((instructionSet) => (
            <ListGroup variant="flush">
              {instructionSet.title && (
                <ListGroup.Item variant="info">
                  <h6>{instructionSet.title}</h6>
                </ListGroup.Item>
              )}
              {instructionSet.steps.map((step, index) => (
                <ListGroup.Item>
                  <Badge variant="light">{index + 1}</Badge>
                  &nbsp;{this.createHtml(step)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default CookingInstructions;