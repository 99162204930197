import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadCategories } from '../../../state/store/actions/categories';
import { saveRecipe } from '../../../state/store/actions/recipes';

const emptyInstructionSet = {
  title: '',
  steps: [],
};
const defaultInstructionSets = [
  emptyInstructionSet,
];
const defaultIngredients = [];
const defaultFacts = [];
const servingTimes = [];

class AddOrUpdateRecipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipe: {
        instructionSets: defaultInstructionSets,
        ingredients: defaultIngredients,
        facts: defaultFacts,
        categories: [],
        servingTimes,
        ...props.recipe,
      },      
    };
  }
  componentDidMount() {
    const { dispatch, categories } = this.props;
    if (!categories || categories.length === 0) {
      dispatch(loadCategories());
    }
  }
  getIngredientsValue = () => {
    return this.state.recipe.ingredients.join('\n');
  }
  getInstructionsValue = (instructionSet) => {
    return instructionSet.steps.join('\n');
  }
  getFactsValue = () => (
    this.state.recipe.facts.map((fact) => {
      if (fact.title && typeof(fact.value) !== 'undefined') {
        return `${fact.title}:${fact.value}`;
      }
      return fact.title;
    }).join('\n')
  )
  isServingTimeChecked = (id) => {
    return this.state.recipe.servingTimes.includes(id.toLowerCase());
  }
  isCategorySelected = (id) => {
    return this.state.recipe.categories.includes(id);
  }
  clearForm = () => {
    const emptyRecipe = {
      title: '',
      description: '',
      instructionSets: [{
        title: '',
        steps: [],
      }],
      ingredients: [],
      facts: [],
      categories: [],
      servingTimes: [],
    };
    this.setState({
      recipe: emptyRecipe,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    console.log('----- will submit this recipe object: ', JSON.stringify(this.state.recipe));
    dispatch(saveRecipe(this.state.recipe)).then((resp) => {
      this.clearForm();
    });
  }

  handleTitleChange = (evt) => {
    this.setState({
      recipe: {
        ...this.state.recipe,
        title: evt.target.value,
      },
    });
    console.log(this.state.recipe);
  }
  handleDescriptionChange = (evt) => {
    this.setState({
      recipe: {
        ...this.state.recipe,
        description: evt.target.value,
      },
    });
  }
  handleIngredientsChange = (evt) => {
    const ingredients = evt.target.value.split('\n');
    this.setState({
      recipe: {
        ...this.state.recipe,
        ingredients,
      },
    });
  }

  handleInstructionSetTitleChange = (value, instructionSetIndex) => {
    const instructionSets = [...this.state.recipe.instructionSets];
    instructionSets[instructionSetIndex].title = value;
    this.setState({
      recipe: {
        ...this.state.recipe,
        instructionSets,
      },
    });
  }
  handleInstructionStepsChange = (value, index) => {
    const instructionSets = [...this.state.recipe.instructionSets];
    instructionSets[index].steps = value.split('\n');
    this.setState({
      recipe: {
        ...this.state.recipe,
        instructionSets,
      },
    });
  }
  addInstructionSet = () => {
    const instructionSets = [...this.state.recipe.instructionSets];
    instructionSets.push({
      title: '',
      steps: [],
    });
    this.setState({
      recipe: {
        ...this.state.recipe,
        instructionSets,
      },
    });
  }
  handleFactsChange = (evt) => {
    const lines = evt.target.value.split('\n');
    const facts = lines.map((line) => {
      const tokens = line.split(':');
      return {
        id: tokens[0].replace(/\s+/g, '_').toLowerCase(),
        title: tokens[0],
        value: tokens[1],
      };
    });
    this.setState({
      recipe: {
        ...this.state.recipe,
        facts,
      },
    });
  }

  onServingTimeChecked = (evt) => {
    let times = [...this.state.recipe.servingTimes];
    const time = evt.target.id;
    if (evt.target.checked && !times.includes(time)) {
      times.push(time);
    } else {
      times = times.filter(t => t !== time);
    }
    this.setState({
      recipe: {
        ...this.state.recipe,
        servingTimes: times,
      },
    });
  }
  onCategoryChange = (evt) => {
    const selectedOptions = [...evt.target.selectedOptions].map(opt => opt.value);
    this.setState({
      recipe: {
        ...this.state.recipe,
        categories: selectedOptions,
      },
    });
    
  }
  render() {
    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="recipe.title">
            <Form.Label>Recipe name or title</Form.Label>
            <Form.Control onChange={this.handleTitleChange} value={this.state.recipe.title} type="text" placeholder="Recipe name or title" />
            <Form.Text className="text-muted">
              Enter the title of the recipe
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="recipe.description">
            <Form.Label>Description</Form.Label>
            <Form.Control onChange={this.handleDescriptionChange} value={this.state.recipe.description} as="textarea" rows="3" placeholder="Recipe description" />
            <Form.Text className="text-muted">
              Enter the description of recipe
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="recipe.servingTimes">
            <Form.Label>Best serving times (check all if apply)</Form.Label>
            <div className="mb-3">
              <Form.Check custom onChange={this.onServingTimeChecked} checked={this.isServingTimeChecked('Breakfast')} inline type="checkbox" label="Breakfast" id="breakfast" />
              <Form.Check custom onChange={this.onServingTimeChecked} checked={this.isServingTimeChecked('Lunch')} inline type="checkbox" label="Lunch" id="lunch" />
              <Form.Check custom onChange={this.onServingTimeChecked} checked={this.isServingTimeChecked('Dinner')} inline type="checkbox" label="Dinner" id="dinner" />
            </div>
          </Form.Group>

          <Form.Group controlId="recipe.categories">
            <Form.Label>Category - choose one or more.</Form.Label>
            <Form.Control onChange={this.onCategoryChange} as="select" multiple>
              {this.props.categories.map((category) => (
                <option selected={this.isCategorySelected(category.id)} value={category.id}>{category.name}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="recipe.ingredients">
            <Form.Label>Ingredients</Form.Label>
            <Form.Control onChange={this.handleIngredientsChange} value={this.getIngredientsValue()} as="textarea" rows="3" placeholder="Recipe ingredients" />
            <Form.Text className="text-muted">
              Ingredients, one per line (hit Enter after typing an ingredient)
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="recipe.instructionSets">
            <Form.Label><b>Instruction sets (if a recipe involves preparing multiple items, each of them has separate steps)</b></Form.Label>
            <br />
            {this.state.recipe.instructionSets.map((instructionSet, index) => (
              <React.Fragment>
                <Form.Label>Instruction title</Form.Label>
                <Form.Control
                  onChange={(evt) => this.handleInstructionSetTitleChange(evt.target.value, index)}
                  value={instructionSet.title}
                  type="text"
                  placeholder="Recipe instructions title"
                />
                <Form.Text className="text-muted">
                  If there is only one dish in the recipe, you can leave the title empty.
                </Form.Text>
                <Form.Label>Steps (simply type a complete step and press Enter. Do not press enter before you have finished typing a step)</Form.Label>
                <Form.Control
                  onChange={(evt) => this.handleInstructionStepsChange(evt.target.value, index)}
                  value={this.getInstructionsValue(instructionSet)}
                  as="textarea"
                  rows="3"
                  placeholder="Recipe steps"
                />
                <Form.Text className="text-muted">
                  Steps, press enter when done typing a step and continue with next step and so on.
                </Form.Text>
              </React.Fragment>
            ))}
            <Button className="save-button" variant="primary" type="button" onClick={this.addInstructionSet}>+Instructions</Button>
          </Form.Group>

          <Form.Group controlId="recipe.facts">
            <Form.Label>Recipe facts (type as Fact name:Fact Value and press enter for next one)</Form.Label>
            <Form.Control onChange={this.handleFactsChange} value={this.getFactsValue()} as="textarea" rows="3" placeholder="Recipe facts" />
            <Form.Text className="text-muted">
              Facts, one per line as Fact:Value(hit Enter after typing a fact)
            </Form.Text>
          </Form.Group>

          <Button className="save-button" variant="primary" type="submit" disabled={this.props.savingRecipe}>
            {(
              this.props.savingRecipe ?
              <React.Fragment>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                >
                  <span className="sr-only">Saving...</span>
                </Spinner>
                <span>&nbsp;Saving...</span>
              </React.Fragment>
              : <span>Save</span>
            )}
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

AddOrUpdateRecipe.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { savingRecipe } = state.recipeList;
  const { categories } = state.itemCategories;
  return {
    savingRecipe,
    categories,
  }
}

export default connect(mapStateToProps)(AddOrUpdateRecipe)
