import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CardColumns from 'react-bootstrap/CardColumns';
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SpoonAndForkIcon from './icons/SpoonAndFork';
import RecipeCard from './RecipeCard';

import { loadRecipes } from '../state/store/actions/recipes';

class RecipeList extends React.Component {
  componentDidMount() {
    const { dispatch, recipes, recipesPreLoaded } = this.props;
    if(recipesPreLoaded) {
      return;
    }
    if (!recipes || recipes.length === 0) {
      dispatch(loadRecipes());
    }
  }
  getRecipeList() {
    const { recipes, recipesPreLoaded } = this.props;
    return recipesPreLoaded || recipes;
  }
  filterByCategory() {
    const { match } = this.props;
    const recipes = this.getRecipeList();
    const { category } = match ? match.params : {};
    if (category && category !== 'all') {
      return recipes.filter((recipe) =>(recipe.categories.includes(category)));
    }
    return recipes;
  }
  render() {
    const { fetchingRecipes } = this.props;
    const filteredList = this.filterByCategory();
    const { category } = this.props.match ? this.props.match.params : {};
    return (
      <React.Fragment>
        <Row>
          <Col md={12} className="m-1 pt-1">
            <h3>
              <SpoonAndForkIcon />
              {(!category || category === 'all') && (
                <span>Latest recipes</span>
              )}
              {category && category !== 'all' && (
                <React.Fragment>
                  <span>Showing latest recipes for {category}&nbsp;</span>
                  <small>
                    <a href="#/home/recipes/all">View all</a>
                  </small>
                  {filteredList.length === 0 && (
                    <span><br /><br />No recipes found.&nbsp;</span>
                  )}
                </React.Fragment>
              )}
            </h3>
            <CardColumns className="card-when-empty">
              {filteredList && filteredList.map((recipe) => (
                <RecipeCard recipe={recipe} />
              ))}
            </CardColumns>
          </Col>
        </Row>

      </React.Fragment>
    )
  }
}

RecipeList.propTypes = {
  recipes: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchingRecipes: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const { recipes, fetchingRecipes } = state.recipeList;
  return {
    recipes,
    fetchingRecipes,
  }
}

export default connect(mapStateToProps)(RecipeList);
