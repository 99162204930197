// recipe categories
export const REQUESTING_CATEGORIES = 'REQUESTING_CATEGORIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const REPLACE_CATEGORY = 'REPLACE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const SAVING_CATEGORY = 'SAVING_CATEGORY';
// recipe list
export const REQUESTING_RECIPES = 'REQUESTING_RECIPES';
export const FETCH_RECIPES = 'FETCH_RECIPES';
export const RECEIEV_RECIPES = 'RECEIEV_RECIPES';
export const REQUESTING_RECIPE_BY_ID = 'REQUESTING_RECIPE_BY_ID';
export const RECEIEV_RECIPE_BY_ID = 'RECEIEV_RECIPE_BY_ID';
export const SAVING_RECIPE = 'SAVING_RECIPE';
export const ADD_OR_UPDATE_RECIPE = 'ADD_OR_UPDATE_RECIPE';
// feedback
export const SUBMITTING_FEEDBACK = 'SUBMITTING_FEEDBACK';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
