import React from 'react';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Coming soon</Popover.Title>
    <Popover.Content>
      Soon you will have the buttons to buy recommended cookware from third party sellers.
    </Popover.Content>
  </Popover>
);


class CookwareList extends React.Component {
  render() {
    const { cookwareList } = this.props;
    return (
      <React.Fragment>
        <div className="h5">Cookware you may need</div>
        <ListGroup>
          {cookwareList.map((cookware) => (
            <ListGroup.Item>
              <Row>
                <Col md={9}>{cookware.name}</Col>
                <Col md={3}>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                  <Link title="Coming soon">Recommendation link</Link>
                </OverlayTrigger>
                </Col>
              </Row>                
            </ListGroup.Item>
          ))}
        </ListGroup>
      </React.Fragment>
    )
  }
}

export default CookwareList;