import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card'

class AboutUs extends React.Component {
  render() {
    return (
      <Row>
        <Col lg={12} className="m-1">
          <Card>
            <Card.Body>
              <Card.Title>About Us</Card.Title>
              <Card.Text>
                <p>Hello! My name is Shafia Kiran. I love to cook as I come from a family who love to cook great and healthy food.</p>
                <p>Here on my website you will find a plenty of recipes to cook very delicious food. You will love cooking by following our
                  recipes and the food will taste great. We also have recipes for the food you eat daily and it will be healthy.
                </p>
                <p>
                  I cook the food that's not only for special occasions but also for your everday
                  calorie needs. On our website you will find a lot of recipes for healthy or diet food as well.
                </p>
                <p>
                  Have thoughts to share with us? We would love to hear your feedback.
                </p>              
              </Card.Text>
              <Button variant="primary" className="m-2">
                <Link className="link-primary" to={`/home/contact`}>
                  Provide feedback
                </Link>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default AboutUs;