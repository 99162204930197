import React from 'react';
import './App.scss';
import { Redirect, Route, Switch, HashRouter as Router } from "react-router-dom";



import Home from './components/Home';
import AdminLanding from './components/admin/AdminLanding';
import AdminLogin from './components/admin/AdminLogin';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => (
          <Redirect to="/home" />
        )} />
        <Route path="/home" component={Home} />
        <Route path="/admin" component={AdminLanding} />
        <Route path="/admin-login" component={AdminLogin} />
      </Switch>
    </Router>
  );
}

export default App;
