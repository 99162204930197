import { combineReducers } from 'redux'
import itemCategories from './categories';
import recipeList from './recipes';
import publicFeedback from './public-feedback';

const rootReducer = combineReducers({
  itemCategories,
  recipeList,
  publicFeedback,
});

export default rootReducer;
