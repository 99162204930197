import React from 'react';
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'


class IngredientsList extends React.Component {
  render() {
    const { ingredients } = this.props;
    return (
      <React.Fragment>
        <div className="h5">Ingredients</div>
        <ListGroup variant="flush">
          {ingredients.map((ingredient) => (
            <ListGroup.Item variant="custom">{ingredient}</ListGroup.Item>
          ))}
        </ListGroup>
      </React.Fragment>
    )
  }
}

export default IngredientsList;